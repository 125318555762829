<template>
  <div>
    <v-row>
      <v-col md="12" v-if="locationsGridShow">
        <v-card elevation="10">
          <v-toolbar
            dense
            color="#003d6a"
            elevation="0"
            dark
            style="z-index: 9">
            <v-toolbar-title>Lokasyonlar</v-toolbar-title>
            <v-spacer></v-spacer>
            <div class="d-flex justify-content-end" style="min-width: 550px">
              <input
                type="text"
                v-model="searchFilterInput"
                autocomplete="off"
                class="form-control mr-3"
                style="max-width: 250px"
                placeholder="Arama..."
                v-on:keyup="onFilterTextBoxChanged()" />
              <RoleProvider slug="LOCATION_CREATE">
                <div slot-scope="{ canItPass }">
                  <CButton
                    color="light"
                    variant="outline"
                    size="sm"
                    @click="
                      cleanLocationModalAddUpdateForm(),
                        cleanSubSiteLocationModalAddUpdateForm(),
                        (locationModalAddUpdateOptions.process = 'add'),
                        (locationModalAddUpdateOptions.title = 'Lokasyon Ekle'),
                        (locationModalAddUpdate = true)
                    "
                    class="py-2 px-3 font-weight-bold"
                    v-if="canItPass">
                    <font-awesome-icon
                      :icon="['fas', 'plus']"
                      class="mr-2" />Yeni Ekle
                  </CButton>
                </div>
              </RoleProvider>
            </div>
          </v-toolbar>

          <CCardBody class="p-0">
            <ag-grid-vue
              style="width: 100%; height: calc(100vh - 145px)"
              class="ag-theme-balham"
              :columnDefs="columnDefs"
              :defaultColDef="defaultColDef"
              :rowData="rowData"
              :sideBar="sideBar"
              :rowSelection="rowSelection"
              :enableRangeSelection="true"
              :statusBar="statusBar"
              @rowDoubleClicked="onRowDoubleClicked"
              @grid-ready="onGridReady"></ag-grid-vue>
          </CCardBody>
        </v-card>
      </v-col>
    </v-row>
    <CModal
      :title="locationModalAddUpdateOptions.title"
      size="xl"
      :closeOnBackdrop="false"
      class="locationModalAddUpdateFormModal"
      :show.sync="locationModalAddUpdate">
      <CRow class="align-items-center topRow">
        <v-col cols="2" class="text-center">
          <v-switch
            v-model="subsiteSwitch"
            :style="{ transform: 'scale(0.9)' }"
            hide-details
            inset
            small
            label="Alt Site"></v-switch>
        </v-col>
        <v-col cols="2" class="text-center">
          <v-select
            class="select"
            v-model="selectedSite"
            v-show="subsiteSwitch"
            :items="subSiteInfo"
            item-text="selectedSiteValue.name"
            item-value="selectedSiteValue"
            @change="getSubsiteLocation()">
          </v-select>
        </v-col>
        <CCol v-if="selectedSite.selected" sm="2">
          <CSelect
            label="SubSite Durumu"
            :value.sync="locationModalAddUpdateForm.subSite.status"
            :options="locationModalAddUpdateFormStatusOptions" />
        </CCol>
        <v-col cols="6" class="text-center">
          <span class="font-weight-bold">
            {{ " Dil Seçimi : " }}
          </span>
          <v-btn-toggle
            v-model="btnToggleLanguage"
            borderless
            mandatory
            :style="{ transform: 'scale(0.9)' }"
            style="width: 60%"
            active-class="secondary white--text">
            <v-btn
              small
              style="width: 50%; border-radius: 50px 0 0 50px"
              :class="{ red: !btnToggleLanguage }"
              class="ls-0 text-capitalize font-weight-bold">
              {{ "ingilizce" }}
            </v-btn>
            <v-btn
              small
              style="width: 50%; border-radius: 0 50px 50px 0"
              :class="{ red: btnToggleLanguage }"
              class="ls-0 text-capitalize font-weight-bold">
              {{ "almanca" }}
            </v-btn>
          </v-btn-toggle></v-col
        >
      </CRow>
      <CRow>
        <!-- TODO HTML image -->
        <CCol sm="12" lg="4">
          <div class="img-car-container">
            <div class="img-car-upload-delete-container">
              <div class="btn-icon-center">
                <font-awesome-icon
                  id="btnResimYukle"
                  icon="cloud-upload-alt"
                  size="2x"
                  title="Resim Yükle"
                  @click="clickImageUpload()" />
                <font-awesome-icon
                  id="btnResimSil"
                  icon="trash-alt"
                  size="2x"
                  class="ml-4"
                  title="Resmi Sil"
                  v-if="
                    locationModalAddUpdateForm.srcImage !==
                    ENV_URL_LOCATION + 'default_location.png'
                  "
                  @click="clickImageDelete()" />
              </div>
              <input
                id="inputLocationImageFile"
                type="file"
                class="files"
                ref="files"
                custom
                v-on:change="selectImage()" />
            </div>
            <CImg :src="locationModalAddUpdateForm.srcImage" fluid />
          </div>

          <CSelect
            label="Tema"
            :value.sync="locationModalAddUpdateForm.theme"
            :options="locationModalAddUpdateFormThemeOptions" />
        </CCol>
        <CCol>
          <CRow>
            <CCol sm="12">
              - <b>Lokasyon Slug <small>(TR)</small> : </b>
              <span>{{ locationModalAddUpdateForm.slug }}</span>
            </CCol>
            <CCol sm="12" v-if="!btnToggleLanguage">
              - <b>Lokasyon Slug <small>(EN)</small> : </b>
              <span>{{ locationModalAddUpdateForm.slugEn }}</span>
            </CCol>
            <CCol sm="12" v-if="btnToggleLanguage">
              - <b>Lokasyon Slug <small>(DE)</small> : </b>
              <span>{{ locationModalAddUpdateForm.slugDe }}</span>
            </CCol>
            <CCol sm="4">
              <CInput
                label="IATA Kodu"
                v-model="locationModalAddUpdateForm.iataCode" />
            </CCol>

            <CCol sm="4">
              <CSelect
                label="Durum"
                :value.sync="locationModalAddUpdateForm.status"
                :options="locationModalAddUpdateFormStatusOptions" />
            </CCol>
            <!-- TODO HTML Şehir -->
            <CCol sm="4">
              Şehir
              <v-autocomplete
                class="mt-2"
                v-model="locationModalAddUpdateForm.city"
                :items="cityNames"
                outlined
                dense></v-autocomplete>
            </CCol>
            <!-- TODO HTML Lokasyon -->
          </CRow>
        </CCol>
      </CRow>

      <CRow class="justify-center">
        <CCol sm="6">
          <CInput
            label="Lokasyon Adı Türkçe"
            v-model="locationModalAddUpdateForm.locationName" />
        </CCol>
        <div
          style="
            display: flex;
            justify-content: center;
            align-items: center !important;
            margin-top: 1rem;
          ">
          <TranslateAWS
            v-if="!loadingAwsIcon && !btnToggleLanguage"
            :onClickIcon="() => clickAwsTranslate('locationname', `en`)"
            :loading="loadingAwsIcon" />
          <TranslateAWS
            v-if="!loadingAwsIcon && btnToggleLanguage"
            :onClickIcon="() => clickAwsTranslate('locationname', `de`)"
            :loading="loadingAwsIcon" />
        </div>
        <CCol sm="5" v-if="!btnToggleLanguage">
          <CInput
            label="Lokasyon Adı İngilizce"
            v-model="locationModalAddUpdateForm.locationNameEn" />
        </CCol>
        <CCol sm="5" v-if="btnToggleLanguage">
          <CInput
            label="Lokasyon Adı Almanca"
            v-model="locationModalAddUpdateForm.locationNameDe" />
        </CCol>
      </CRow>
      <!-- TODO HTML Başlık -->
      <CRow class="justify-center">
        <CCol sm="6">
          <CInput
            v-if="selectedSite.selected"
            :label="`Başlık Türkçe - ${selectedSite.name}`"
            v-model="locationModalAddUpdateForm.subSite.content.tr.title" />
          <CInput
            v-else
            label="Başlık Türkçe"
            v-model="locationModalAddUpdateForm.content.tr.title" />
        </CCol>
        <div
          style="
            display: flex;
            justify-content: center;
            align-items: center !important;
            margin-top: 1rem;
          ">
          <TranslateAWS
            v-if="!loadingAwsIcon && !btnToggleLanguage"
            :onClickIcon="() => clickAwsTranslate('title', `en`)"
            :loading="loadingAwsIcon" />
          <TranslateAWS
            v-if="!loadingAwsIcon && btnToggleLanguage"
            :onClickIcon="() => clickAwsTranslate('title', `de`)"
            :loading="loadingAwsIcon" />
        </div>

        <CCol sm="5" v-if="!btnToggleLanguage">
          <CInput
            v-if="selectedSite.selected"
            :label="`Başlık İngilizce - ${selectedSite.name}`"
            v-model="locationModalAddUpdateForm.subSite.content.en.title" />
          <CInput
            v-else
            label="Başlık İngilizce"
            v-model="locationModalAddUpdateForm.content.en.title" />
        </CCol>
        <CCol sm="5" v-if="btnToggleLanguage">
          <CInput
            v-if="selectedSite.selected"
            :label="`Başlık Almanca - ${selectedSite.name}`"
            v-model="locationModalAddUpdateForm.subSite.content.de.title" />
          <CInput
            v-else
            label="Başlık Almanca"
            v-model="locationModalAddUpdateForm.content.de.title" /> </CCol
      ></CRow>
      <CRow class="justify-center">
        <CCol sm="6">
          <CTextarea
            v-if="selectedSite.selected"
            :label="`Açıklama Türkçe - ${selectedSite.name}`"
            v-model="
              locationModalAddUpdateForm.subSite.content.tr.description
            " />
          <CTextarea
            v-else
            label="Açıklama Türkçe"
            v-model="locationModalAddUpdateForm.content.tr.description"
        /></CCol>
        <div
          style="
            display: flex;
            justify-content: center;
            align-items: center !important;
            margin-top: 1rem;
          ">
          <TranslateAWS
            v-if="!loadingAwsIcon && !btnToggleLanguage"
            :onClickIcon="() => clickAwsTranslate('description', `en`)"
            :loading="loadingAwsIcon" />
          <TranslateAWS
            v-if="!loadingAwsIcon && btnToggleLanguage"
            :onClickIcon="() => clickAwsTranslate('description', `de`)"
            :loading="loadingAwsIcon" />
        </div>

        <CCol sm="5" v-if="!btnToggleLanguage">
          <CTextarea
            v-if="selectedSite.selected"
            :label="`Açıklama İngilizce - ${selectedSite.name}`"
            v-model="
              locationModalAddUpdateForm.subSite.content.en.description
            " />
          <CTextarea
            v-else
            label="Açıklama İngilizce"
            v-model="locationModalAddUpdateForm.content.en.description">
          </CTextarea>
        </CCol>
        <CCol sm="5" v-if="btnToggleLanguage">
          <CTextarea
            v-if="selectedSite.selected"
            :label="`Açıklama Almanca / ${selectedSite.name}`"
            v-model="
              locationModalAddUpdateForm.subSite.content.de.description
            " />
          <CTextarea
            v-else
            label="Açıklama Almanca"
            v-model="locationModalAddUpdateForm.content.de.description">
          </CTextarea>
        </CCol>
      </CRow>

      <CRow class="justify-center">
        <CCol sm="6">
          <ckeditor
            v-if="selectedSite.selected"
            v-model="locationModalAddUpdateForm.subSite.content.tr.richText" />

          <ckeditor
            v-else
            v-model="locationModalAddUpdateForm.content.tr.richText"
        /></CCol>
        <div
          style="
            display: flex;
            justify-content: center;
            align-items: center !important;
            margin-top: 1rem;
          ">
          <TranslateAWS
            v-if="!loadingAwsIcon && !btnToggleLanguage"
            :onClickIcon="() => clickAwsTranslate('ckeditor', `en`)"
            :loading="loadingAwsIcon" />
          <TranslateAWS
            v-if="!loadingAwsIcon && btnToggleLanguage"
            :onClickIcon="() => clickAwsTranslate('ckeditor', `de`)"
            :loading="loadingAwsIcon" />
        </div>

        <CCol sm="5" v-if="!btnToggleLanguage">
          <ckeditor
            v-if="selectedSite.selected"
            v-model="locationModalAddUpdateForm.subSite.content.en.richText" />
          <ckeditor
            v-else
            v-model="locationModalAddUpdateForm.content.en.richText" />
        </CCol>
        <CCol sm="5" v-else>
          <ckeditor
            v-if="selectedSite.selected"
            v-model="locationModalAddUpdateForm.subSite.content.de.richText" />
          <ckeditor
            v-else
            v-model="locationModalAddUpdateForm.content.de.richText" />
        </CCol>
      </CRow>
      <CRow class="justify-center">
        <CCol sm="6">
          <label>Micro Data (Türkçe) {{ selectedSite.name }}</label>
          <CCard>
            <CCardBody v-if="selectedSite.selected">
              <CRow
                v-for="(data, i) in locationModalAddUpdateForm.subSite.microData
                  .tr"
                :key="i">
                <CCol col="11">
                  <CRow>
                    <CCol col="12">
                      <CInput placeholder="Soru" v-model="data.question" />
                    </CCol>
                    <CCol col="12">
                      <CTextarea placeholder="Cevap" v-model="data.answer" />
                    </CCol>
                  </CRow>
                </CCol>
                <CCol class="mb-3 pl-1" col="1">
                  <div
                    class="d-flex align-items-center justify-content-center"
                    style="
                      height: 100%;
                      cursor: pointer;
                      background-color: #eee;
                      border-radius: 5px;
                    "
                    v-if="
                      locationModalAddUpdateForm.subSite.microData.tr.length -
                        1 ==
                      i
                    "
                    @click="microDataAdd('tr')">
                    <font-awesome-icon icon="plus" />
                  </div>
                  <div
                    v-else
                    class="d-flex align-items-center justify-content-center"
                    style="
                      height: 100%;
                      cursor: pointer;
                      background-color: #fff;
                      border-radius: 5px;
                    "
                    @click="microDataDelete(data, 'tr')">
                    <font-awesome-icon icon="trash-alt" />
                  </div>
                </CCol>
              </CRow>
            </CCardBody>
            <CCardBody v-else>
              <CRow
                v-for="(data, i) in locationModalAddUpdateForm.microData.tr"
                :key="i">
                <CCol col="11">
                  <CRow>
                    <CCol col="12">
                      <CInput placeholder="Soru" v-model="data.question" />
                    </CCol>
                    <CCol col="12">
                      <CTextarea placeholder="Cevap" v-model="data.answer" />
                    </CCol>
                  </CRow>
                </CCol>
                <CCol class="mb-3 pl-1" col="1">
                  <div
                    class="d-flex align-items-center justify-content-center"
                    style="
                      height: 100%;
                      cursor: pointer;
                      background-color: #eee;
                      border-radius: 5px;
                    "
                    v-if="
                      locationModalAddUpdateForm.microData.tr.length - 1 == i
                    "
                    @click="microDataAdd('tr')">
                    <font-awesome-icon icon="plus" />
                  </div>
                  <div
                    v-else
                    class="d-flex align-items-center justify-content-center"
                    style="
                      height: 100%;
                      cursor: pointer;
                      background-color: #fff;
                      border-radius: 5px;
                    "
                    @click="microDataDelete(data, 'tr')">
                    <font-awesome-icon icon="trash-alt" />
                  </div>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
        <div
          style="
            display: flex;
            justify-content: flex-end;
            align-items: center !important;
            margin-top: 1rem;
          ">
          <TranslateAWS
            v-if="!loadingAwsIcon && !btnToggleLanguage"
            :onClickIcon="() => clickAwsTranslate('microData', `en`)"
            :loading="loadingAwsIcon" />
          <TranslateAWS
            v-if="!loadingAwsIcon && btnToggleLanguage"
            :onClickIcon="() => clickAwsTranslate('microData', `de`)"
            :loading="loadingAwsIcon" />
        </div>

        <CCol sm="5" v-if="!btnToggleLanguage">
          <label>Micro Data (İngilizce) {{ selectedSite.name }}</label>
          <CCard>
            <CCardBody v-if="selectedSite.selected">
              <CRow
                v-for="(data, i) in locationModalAddUpdateForm.subSite.microData
                  .en"
                :key="i">
                <CCol col="11">
                  <CRow>
                    <CCol col="12">
                      <CInput placeholder="Soru" v-model="data.question" />
                    </CCol>
                    <CCol col="12">
                      <CTextarea placeholder="Cevap" v-model="data.answer" />
                    </CCol>
                  </CRow>
                </CCol>
                <CCol class="mb-3 pl-1" col="1">
                  <div
                    class="d-flex align-items-center justify-content-center"
                    style="
                      height: 100%;
                      cursor: pointer;
                      background-color: #eee;
                      border-radius: 5px;
                    "
                    v-if="
                      locationModalAddUpdateForm.subSite.microData.en.length -
                        1 ==
                      i
                    "
                    @click="microDataAdd('en')">
                    <font-awesome-icon icon="plus" />
                  </div>
                  <div
                    v-else
                    class="d-flex align-items-center justify-content-center"
                    style="
                      height: 100%;
                      cursor: pointer;
                      background-color: #fff;
                      border-radius: 5px;
                    "
                    @click="microDataDelete(data, 'en')">
                    <font-awesome-icon icon="trash-alt" />
                  </div>
                </CCol>
              </CRow>
            </CCardBody>
            <CCardBody v-else>
              <CRow
                v-for="(data, i) in locationModalAddUpdateForm.microData.en"
                :key="i">
                <CCol col="11">
                  <CRow>
                    <CCol col="12">
                      <CInput placeholder="Soru" v-model="data.question" />
                    </CCol>
                    <CCol col="12">
                      <CTextarea placeholder="Cevap" v-model="data.answer" />
                    </CCol>
                  </CRow>
                </CCol>
                <CCol class="mb-3 pl-1" col="1">
                  <div
                    class="d-flex align-items-center justify-content-center"
                    style="
                      height: 100%;
                      cursor: pointer;
                      background-color: #eee;
                      border-radius: 5px;
                    "
                    v-if="
                      locationModalAddUpdateForm.microData.en.length - 1 == i
                    "
                    @click="microDataAdd('en')">
                    <font-awesome-icon icon="plus" />
                  </div>
                  <div
                    v-else
                    class="d-flex align-items-center justify-content-center"
                    style="
                      height: 100%;
                      cursor: pointer;
                      background-color: #fff;
                      border-radius: 5px;
                    "
                    @click="microDataDelete(data, 'en')">
                    <font-awesome-icon icon="trash-alt" />
                  </div>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>

        <CCol sm="5" v-if="btnToggleLanguage">
          <label>Micro Data (Almanca) {{ selectedSite.name }}</label>
          <CCard>
            <CCardBody v-if="selectedSite.selected">
              <CRow
                v-for="(data, i) in locationModalAddUpdateForm.subSite.microData
                  .de"
                :key="i">
                <CCol col="11">
                  <CRow>
                    <CCol col="12">
                      <CInput placeholder="Soru" v-model="data.question" />
                    </CCol>
                    <CCol col="12">
                      <CTextarea placeholder="Cevap" v-model="data.answer" />
                    </CCol>
                  </CRow>
                </CCol>
                <CCol class="mb-3 pl-1" col="1">
                  <div
                    class="d-flex align-items-center justify-content-center"
                    style="
                      height: 100%;
                      cursor: pointer;
                      background-color: #eee;
                      border-radius: 5px;
                    "
                    v-if="
                      locationModalAddUpdateForm.subSite.microData.de.length -
                        1 ==
                      i
                    "
                    @click="microDataAdd('de')">
                    <font-awesome-icon icon="plus" />
                  </div>
                  <div
                    v-else
                    class="d-flex align-items-center justify-content-center"
                    style="
                      height: 100%;
                      cursor: pointer;
                      background-color: #fff;
                      border-radius: 5px;
                    "
                    @click="microDataDelete(data, 'de')">
                    <font-awesome-icon icon="trash-alt" />
                  </div>
                </CCol>
              </CRow>
            </CCardBody>
            <CCardBody v-else>
              <CRow
                v-for="(data, i) in locationModalAddUpdateForm.microData.de"
                :key="i">
                <CCol col="11">
                  <CRow>
                    <CCol col="12">
                      <CInput placeholder="Soru" v-model="data.question" />
                    </CCol>
                    <CCol col="12">
                      <CTextarea placeholder="Cevap" v-model="data.answer" />
                    </CCol>
                  </CRow>
                </CCol>
                <CCol class="mb-3 pl-1" col="1">
                  <div
                    class="d-flex align-items-center justify-content-center"
                    style="
                      height: 100%;
                      cursor: pointer;
                      background-color: #eee;
                      border-radius: 5px;
                    "
                    v-if="
                      locationModalAddUpdateForm.microData.de.length - 1 == i
                    "
                    @click="microDataAdd('de')">
                    <font-awesome-icon icon="plus" />
                  </div>
                  <div
                    v-else
                    class="d-flex align-items-center justify-content-center"
                    style="
                      height: 100%;
                      cursor: pointer;
                      background-color: #fff;
                      border-radius: 5px;
                    "
                    @click="microDataDelete(data, 'de')">
                    <font-awesome-icon icon="trash-alt" />
                  </div>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CCol>
        <div class="blog-files-header">
          <h4>Lokasyon Ek Fotoğraflar</h4>

          <input
            hidden
            id="blogFilesInput"
            type="file"
            class="files"
            ref="blogFiles"
            custom
            multiple
            v-on:change="blogFilesInputChange()" />
          <div>
            <label>
              <multiselect
                v-model="locationModalAddUpdateForm.campaignImagesValue"
                :options="multiSelect.campaignOptions"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="true"
                :preserve-search="true"
                placeholder="Kampanya Fotoğrafları Seç"
                label="name"
                track-by="name"
                selectLabel="Seçmek için tıkla"
                deselectLabel="Çıkartmak için tıkla"
                selectedLabel="Seçili">
                <template slot="selection" slot-scope="{ values, isOpen }"
                  ><span
                    class="multiselect__single"
                    v-if="values.length &amp;&amp; !isOpen"
                    >{{ values.length }} adet seçili</span
                  ></template
                >
              </multiselect>
            </label>
            <label for="blogFilesInput">Fotoğraf Yükle </label>
          </div>
        </div>

        <CCard class="p-4 mt-2">
          <CRow
            v-if="locationModalAddUpdateForm.blogPhotos.length"
            class="mt-3">
            <div
              class="blog-photo-wr"
              v-for="(blogPhoto, i) in locationModalAddUpdateForm.blogPhotos"
              :key="i">
              <CImg class="blog-photo" :src="blogPhoto" fluid />
              <font-awesome-icon
                id="btnResimSil"
                icon="trash-alt"
                size="2x"
                class="ml-4"
                title="Resmi Sil"
                @click="blogFilesInputDelete(i)" />
            </div>
          </CRow>
          <p class="not-found-photo" v-else>Fotoğraf Bulunamadı</p>
        </CCard>
      </CCol>
      <CCol v-if="locationModalAddUpdateForm.schendler">
        <CCard class="p-4 mt-2">
          <div class="search-schedule">
            <h4>Arama Sonuçları</h4>
            <span>
              <strong> Son Arama Tarihi </strong> :
              {{
                moment(
                  locationModalAddUpdateForm.schendler.lastSearchDate
                ).format("DD.MM.YYYY HH:mm")
              }}
            </span>
          </div>
          <CRow class="mt-3">
            <CCol sm="3">
              <CInput
                :disabled="true"
                :value="locationModalAddUpdateForm.schendler?.carCount"
                label="Araç Adet" />
            </CCol>
            <CCol sm="3">
              <CInput
                style="margin-bottom: 0"
                :disabled="true"
                :value="locationModalAddUpdateForm.schendler?.vendorCount"
                label="Tedarikçi Adet" />
              <div v-if="locationModalAddUpdateForm.schendler?.vendors?.length">
                <span
                  v-for="(item, index) in locationModalAddUpdateForm.schendler
                    .vendors"
                  :key="index">
                  {{ item }},
                </span>
              </div>
            </CCol>
            <CCol sm="3">
              <CInput
                :disabled="true"
                :value="locationModalAddUpdateForm.schendler?.minPrice + ' TL'"
                label="Min Günlük Fiyat" />
            </CCol>
            <CCol sm="3">
              <CInput
                :disabled="true"
                :value="locationModalAddUpdateForm.schendler?.maxPrice + ' TL'"
                label="Max Günlük Fiyat" />
            </CCol>
          </CRow>
        </CCard>
      </CCol>
      <template #footer>
        <RoleProvider slug="LOCATION_CREATE">
          <div slot-scope="{ canItPass }">
            <CButton
              color="success"
              v-if="locationModalAddUpdateOptions.process == 'add' && canItPass"
              :disabled="locationModalAddUpdateBtnDisable"
              @click="locationAddUpdate(locationModalAddUpdateForm)"
              >Ekle
            </CButton>
          </div>
        </RoleProvider>
        <RoleProvider slug="LOCATION_UPDATE">
          <div slot-scope="{ canItPass }">
            <CButton
              color="success text-white"
              v-if="
                locationModalAddUpdateOptions.process == 'update' && canItPass
              "
              :disabled="locationModalAddUpdateBtnDisable"
              @click="locationAddUpdate(locationModalAddUpdateForm)">
              Güncelle</CButton
            >
          </div>
        </RoleProvider>
        <RoleProvider slug="LOCATION_DELETE">
          <div slot-scope="{ canItPass }">
            <CButton
              v-if="
                locationModalAddUpdateOptions.process == 'update' && canItPass
              "
              color="danger text-white"
              @click="
                selectedSite.selected
                  ? locationDelete({
                      _id: locationModalAddUpdateForm.subSite._id,
                    })
                  : locationDelete({ _id: locationModalAddUpdateForm._id })
              "
              >{{
                selectedSite.selected
                  ? `${selectedSite.name} Lokasyon Sil`
                  : "Sil"
              }}
            </CButton>
          </div>
        </RoleProvider>
      </template>
    </CModal>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import axios from "axios";
import Func from "../../func";
import Vue from "vue";
import ckeditor from "ckeditor4-vue"; // https://ckeditor.com/docs/ckeditor4/latest/guide/dev_vue.html#basic-usage
import { RoleProvider } from "../../provider";
import Multiselect from "vue-multiselect";
import moment from "moment";
import TranslateAWS from "../../components/TranslateAWS.vue";
Vue.use(ckeditor);
axios.defaults.withCredentials = true;

export default {
  name: "LocationSearch",
  data() {
    return {
      loadingAwsIcon: false,
      editorConfig: {},
      multiSelect: {
        campaignOptions: [],
      },
      subsiteSwitch: false,
      subSiteTitle: null,
      selectedSite: {
        id: "",
        name: "",
        selected: false,
      },
      subSiteInfo: [],
      // AG GID
      ENV_URL_LOCATION: process.env.VUE_APP_CDN_URL_LOCATION,
      locationsGridShow: false,
      cities: [],
      cityNames: [],
      defaultLocationImage: "default_location.png",
      columnDefs: [
        {
          field: "image",
          valueGetter: (params) => {
            if (params.data.image == "default_location.png") {
              return "YOK";
            } else {
              return "MEVCUT";
            }
          },
          headerName: "Görsel",
          width: 130,
        },
        {
          field: "status",
          cellRenderer: (params) => {
            return params.value ? "Aktif" : "Pasif";
          },
          cellStyle: (params) => {
            return params.value
              ? { color: "#2eb85c", fontWeight: "bold" }
              : { color: "#e55353", fontWeight: "bold" };
          },
          headerName: "Durum",
          width: 100,
        },
        {
          field: "content.tr.title",
          headerName: "Başlık",
          width: 150,
        },
        {
          field: "content.tr.description",
          headerName: "Açıklama",
          width: 250,
        },
        {
          field: "city",
          headerName: "Şehir",
          width: 150,
        },
        {
          field: "locationName",
          headerName: "Lokasyon Adı",
          width: 200,
        },
        {
          field: "iataCode",
          headerName: "IATA Kodu",
          width: 120,
        },
        {
          field: "content.tr.richText",
          headerName: "SEO Metin",
          width: 400,
        },
        {
          field: "slug",
          headerName: "Slug",
          width: 400,
        },
        //schendler
        {
          field: "schendler.lastSearchDate",
          headerName: "Son Arama T.",
          width: 140,
          cellRenderer: ({ value }) => moment(value).format("DD.MM.YYYY HH:mm"),
        },
        {
          field: "vendors",
          headerName: "Eşleştirilmiş Lokasyonlar",
          width: 200,
          valueGetter: (params) => params.data.vendors.length,
        },
        {
          field: "vendors",
          headerName: "Eşleştirilmiş Tedarikçiler",
          width: 200,
          valueGetter: (params) =>
            params.data.vendors.map((vendor) => vendor.vendorSlug).join(", "),
        },
        {
          field: "schendler.carCount",
          headerName: "Dönen Araç",
          width: 120,
        },
        {
          field: "schendler.vendorCount",
          headerName: "Dönen Tedarikçi",
          width: 120,
        },
        {
          field: "schendler.minPrice",
          headerName: "Min Günlük Fiyat",
          width: 150,
        },
        {
          field: "schendler.maxPrice",
          headerName: "Max Günlük Fiyat",
          width: 150,
        },
      ],
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true,
        filterParams: {
          newRowsAction: "keep",
        },
      },
      rowData: [],
      sideBar: {
        // Grid yan çubuk tanımlaması
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Sütunlar",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          ,
          {
            id: "filters",
            labelDefault: "Filtreler",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
        defaultToolPanel: "",
      },
      rowSelection: "multiple", // Satır Seçim Türü
      statusBar: {
        // Durum çubuğunda kullanılacak durum çubuğu bileşenlerini belirtir.
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left",
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center",
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" },
        ],
      },
      // LOKASYON ARAMA
      searchLocationFormCollapsed: false,
      searchLocationFormStatusOptions: [
        {
          value: "",
          label: "Tümü",
        },
        {
          value: true,
          label: "Aktif",
        },
        {
          value: false,
          label: "Pasif",
        },
      ],
      searchLocationForm: {
        status: "",
        city: "",
        locationName: "",
      },
      // LOKASYON EKLEME GÜNCELLEME MODAL
      locationModalAddUpdate: false, // Car Modal Add Update
      locationModalAddUpdateBtnDisable: false, // Car Modal Add Update button disabled enabled değişkeni
      locationModalAddUpdateOptions: {
        // TODO locationModalAddUpdateOptions
        // Car Modal Add Update değişkenleri
        process: "",
        city: "",
        locationName: "",
        title: "",
      },
      locationModalAddUpdateFormStatusOptions: [
        {
          value: true,
          label: "Aktif",
        },
        {
          value: false,
          label: "Pasif",
        },
      ],
      locationModalAddUpdateFormThemeOptions: [
        {
          value: false,
          label: "Light",
        },
        {
          value: true,
          label: "Dark",
        },
      ],
      btnToggleLanguage: 0,
      locationModalAddUpdateForm: {
        // TODO locationModalAddUpdateForm
        _id: "",
        status: true,
        city: "",
        locationName: "",
        locationNameEn: "",
        locationNameDe: "",
        theme: false,
        iataCode: "",
        slug: "",
        slugEn: "",
        slugDe: "",
        oldSlug: "",
        srcImage: "",
        title: "",
        files: null,
        description: "",
        richText: "",
        siteTitleTr: "",
        siteTitleEn: "",
        siteTitleDe: "",
        campaignImagesValue: [],
        content: {
          tr: {
            title: "",
            description: "",
            richText: "",
          },
          en: {
            title: "",
            description: "",
            richText: "",
          },
          de: {
            title: "",
            description: "",
            richText: "",
          },
        },
        microData: {
          tr: [
            {
              question: "",
              answer: "",
            },
          ],
          en: [
            {
              question: "",
              answer: "",
            },
          ],
          de: [
            {
              question: "",
              answer: "",
            },
          ],
        },
        subSite: {
          microData: {
            tr: [
              {
                question: "",
                answer: "",
              },
            ],
            en: [
              {
                question: "",
                answer: "",
              },
            ],
            de: [
              {
                question: "",
                answer: "",
              },
            ],
          },
          content: {
            tr: {
              title: "",
              description: "",
              richText: "",
            },
            en: {
              title: "",
              description: "",
              richText: "",
            },
            de: {
              title: "",
              description: "",
              richText: "",
            },
          },
          subSiteId: "",
          status: true,
          subSiteName: "",
          locationId: "",
        },
        blogPhotos: [],
      },
      searchFilterInput: "",
    };
  },
  components: {
    AgGridVue,
    RoleProvider,
    Multiselect,
    TranslateAWS,
  },
  watch: {
   
    "locationModalAddUpdateForm.locationName": function (value) {
      if (this.locationModalAddUpdateOptions.process === "update")
        return (this.locationModalAddUpdateForm.slug = Func.slugify(value));
    },
    "locationModalAddUpdateForm.locationNameEn": function (value) {
      if (this.locationModalAddUpdateOptions.process === "update")
        return (this.locationModalAddUpdateForm.slugEn = Func.slugify(value));
    },
    "locationModalAddUpdateForm.locationNameDe": function (value) {
      if (this.locationModalAddUpdateOptions.process === "update")
        return (this.locationModalAddUpdateForm.slugDe = Func.slugify(value));
    },
    subsiteSwitch(value) {
      value
        ? this.subSiteInfos()
        : this.cleanSubSiteLocationModalAddUpdateForm();
    },
  },
  methods: {
    async getSubsiteLocation() {
      this.selectedSite.selected = true;
      this.locationModalAddUpdateForm.subSite.subSiteId = this.selectedSite.id;
      this.locationModalAddUpdateForm.subSite.subSiteName =
        this.selectedSite.name;
      this.locationModalAddUpdateForm.subSite.locationId =
        this.locationModalAddUpdateForm._id;
      if (this.locationModalAddUpdateOptions.process === "update") {
        await axios
          .get(process.env.VUE_APP_API_URL + "admin/location", {
            params: {
              locationId: this.locationModalAddUpdateForm._id,
              subSiteId: this.selectedSite.id,
            },
          })
          .then((response) => {
            if (response.data.result !== "error")
              this.locationModalAddUpdateForm.subSite = response.data.result;
          });
      }
    },
    async subSiteInfos() {
      try {
        await axios
          .get(process.env.VUE_APP_API_URL + "admin/subsites")
          .then((response) => {
            this.subSiteInfo = response.data.subSites.map((item) => {
              return {
                ...item,
                selectedSiteValue: {
                  name: item.name,
                  id: item._id,
                },
              };
            });
          });
      } catch (error) {
        console.log(error);
      }
    },
    cleanSubSiteLocationModalAddUpdateForm() {
      this.selectedSite.selected = false;
      this.selectedSite.id = "";
      (this.selectedSite.name = ""), (this.subsiteSwitch = false);
      this.locationModalAddUpdateForm.subSite = {
        microData: {
          tr: [
            {
              question: "",
              answer: "",
            },
          ],
          en: [
            {
              question: "",
              answer: "",
            },
          ],
          de: [
            {
              question: "",
              answer: "",
            },
          ],
        },
        content: {
          tr: {
            title: "",
            description: "",
            richText: "",
          },
          en: {
            title: "",
            description: "",
            richText: "",
          },
          de: {
            title: "",
            description: "",
            richText: "",
          },
        },
        status: true,
        subSiteId: "",
        subSiteName: "",
        locationId: "",
      };
    },
    async clickAwsTranslate(where, target) {
      const texts = {
        ckeditor: {
          en: this.selectedSite.selected
            ? this.locationModalAddUpdateForm.subSite.content.en.richText
            : this.locationModalAddUpdateForm.content.en.richText,
          tr: this.selectedSite.selected
            ? this.locationModalAddUpdateForm.subSite.content.tr.richText
            : this.locationModalAddUpdateForm.content.tr.richText,
          de: this.selectedSite.selected
            ? this.locationModalAddUpdateForm.subSite.content.de.richText
            : this.locationModalAddUpdateForm.content.de.richText,

          setData: (t, target) => {
            this.locationModalAddUpdateForm.content[target].richText = t;
          },
          setDataSubSite: (t, target) => {
            this.locationModalAddUpdateForm.subSite.content[target].richText =
              t;
          },
        },
        description: {
          en: this.selectedSite.selected
            ? this.locationModalAddUpdateForm.subSite.content.en.description
            : this.locationModalAddUpdateForm.content.en.description,
          tr: this.selectedSite.selected
            ? this.locationModalAddUpdateForm.subSite.content.tr.description
            : this.locationModalAddUpdateForm.content.tr.description,
          de: this.selectedSite.selected
            ? this.locationModalAddUpdateForm.subSite.content.de.description
            : this.locationModalAddUpdateForm.content.de.description,
          setData: (t, target) => {
            this.locationModalAddUpdateForm.content[target].description = t;
          },
          setDataSubSite: (t, target) => {
            this.locationModalAddUpdateForm.subSite.content[
              target
            ].description = t;
          },
        },
        title: {
          en: this.selectedSite.selected
            ? this.locationModalAddUpdateForm.subSite.content.en.title
            : this.locationModalAddUpdateForm.content.en.title,
          tr: this.selectedSite.selected
            ? this.locationModalAddUpdateForm.subSite.content.tr.title
            : this.locationModalAddUpdateForm.content.tr.title,
          de: this.selectedSite.selected
            ? this.locationModalAddUpdateForm.subSite.content.de.title
            : this.locationModalAddUpdateForm.content.de.title,
          setData: (t, target) => {
            this.locationModalAddUpdateForm.content[target].title = t;
          },
          setDataSubSite: (t, target) => {
            this.locationModalAddUpdateForm.subSite.content[target].title = t;
          },
        },
        microData: {
          en: this.selectedSite.selected
            ? this.locationModalAddUpdateForm.subSite.microData.en
            : this.locationModalAddUpdateForm.microData.en,
          tr: this.selectedSite.selected
            ? this.locationModalAddUpdateForm.subSite.microData.tr
            : this.locationModalAddUpdateForm.microData.tr,
          de: this.selectedSite.selected
            ? this.locationModalAddUpdateForm.subSite.microData.de
            : this.locationModalAddUpdateForm.microData.de,
          setData: (t, target) => {
            this.locationModalAddUpdateForm.microData[target] = t;
          },
          setDataSubSite: (t, target) => {
            this.locationModalAddUpdateForm.subSite.microData[target] = t;
          },
        },
        locationname: {
          tr: this.locationModalAddUpdateForm.locationName,
          en: this.locationModalAddUpdateForm.locationNameEn,
          de: this.locationModalAddUpdateForm.locationNameDe,
          setData: (t, target) => {
            this.locationModalAddUpdateForm[
              `locationName${target.charAt(0).toUpperCase()}${target.slice(1)}`
            ] = t;
          },
          setDataSubSite: (t, target) => {
            this.locationModalAddUpdateForm[
              `locationName${target.charAt(0).toUpperCase()}${target.slice(1)}`
            ] = t;
          },
        },
      };
      try {
        const source = "tr";
        const sourceTextOrObject = texts[where][source];
        this.loadingAwsIcon = true;

        if (typeof sourceTextOrObject == "object") {
          let translations = sourceTextOrObject.map(async (item) => ({
            question: await this.awsTranslate(item.question, source, target),
            answer: await this.awsTranslate(item.answer, source, target),
          }));
          translations = await Promise.all(translations);
          this.selectedSite.selected
            ? texts[where].setDataSubSite(translations, target)
            : texts[where].setData(translations, target);
        } else {
          const text = await this.awsTranslate(
            sourceTextOrObject,
            source,
            target
          );
          this.selectedSite.selected
            ? texts[where].setDataSubSite(text, target)
            : texts[where].setData(text, target);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingAwsIcon = false;
      }
    },
    async awsTranslate(text, source, target) {
      try {
        const { data } = await axios.post(
          process.env.VUE_APP_API_URL + "admin/aws-translate",
          {
            text,
            source,
            target,
          }
        );

        return data || "error";
      } catch (error) {
        console.log(error.response);
      }
    },

    async getCampaigns() {
      try {
        let response = await axios.get(
          process.env.VUE_APP_API_URL + "admin/campaign"
        );
        this.multiSelect.campaignOptions = response.data.map((item) => ({
          name: item.label,
          id: item.id,
          image: item.image.desktop,
        }));
      } catch (e) {
        console.table(e);
      }
    },
    blogFilesInputDelete(index) {
      this.locationModalAddUpdateForm.blogPhotos.splice(index, 1);
    },
    blogFilesInputChange() {
      const _this = this;
      const files = this.$refs.blogFiles.files;
      for (let index = 0; index < files.length; index++) {
        const fileImage = files[index];
        if (fileImage.size > 1048576) {
          Vue.swal({
            icon: "error",
            title: "Lokasyon Ekle - Resim",
            html: "Maksimum 1 MB resim yükleyebilirsiniz!",
            confirmButtonText: "Tamam",
          });
        } else {
          if (
            fileImage.type === "image/png" ||
            fileImage.type === "image/jpg" ||
            fileImage.type === "image/jpeg"
          ) {
            let reader = new FileReader();

            reader.onload = function (e) {
              _this.locationModalAddUpdateForm.blogPhotos.push(e.target.result);
            };
            reader.readAsDataURL(fileImage);
          } else {
            Vue.swal({
              icon: "error",
              title: "Lokasyon Ekle - Resim",
              html: "Yalnızca png, jpg ve jpeg formatlı resimleri yükleyebilirsiniz!",
              confirmButtonText: "Tamam",
            });
          }
        }
      }
    },
    microDataAdd(lang) {
      var microData = {
        question: "",
        answer: "",
      };
      selectedSite.selected
        ? this.locationModalAddUpdateForm.subSite.microData[lang].push(
            microData
          )
        : this.locationModalAddUpdateForm.microData[lang].push(microData);
    },

    microDataDelete(val, lang) {
      Vue.swal
        .fire({
          icon: "question",
          text: "Silmek istiyor musunuz?",
          showCancelButton: true,
          confirmButtonText: "Evet",
          cancelButtonText: "Hayır",
          showCloseButton: true,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            var item = selectedSite.selected
              ? this.locationModalAddUpdateForm.subSite.microData[lang].indexOf(
                  val
                )
              : this.locationModalAddUpdateForm.microData[lang].indexOf(val);

            selectedSite.selected
              ? this.locationModalAddUpdateForm.subSite.microData[lang].splice(
                  item,
                  1
                )
              : this.locationModalAddUpdateForm.microData[lang].splice(item, 1);
          }
        });
    },

    onFilterTextBoxChanged() {
      this.gridApi.setQuickFilter(this.searchFilterInput);
    },

    clickImageUpload() {
      // TODO clickImageUpload
      document.getElementById("inputLocationImageFile").click();
    },

    clickImageDelete() {
      // TODO clickImageDelete
      this.locationModalAddUpdateForm.srcImage =
        this.ENV_URL_LOCATION + this.defaultLocationImage;
      this.locationModalAddUpdateForm.files = null;
      document.getElementById("inputLocationImageFile").value = "";
    },

    selectImage() {
      // TODO selectImage
      const _this = this;
      const fileImage = this.$refs.files.files[0];

      if (fileImage.size > 1048576) {
        Vue.swal({
          icon: "error",
          title: "Lokasyon Ekle - Resim",
          html: "Maksimum 1 MB resim yükleyebilirsiniz!",
          confirmButtonText: "Tamam",
        });
      } else {
        if (
          fileImage.type === "image/png" ||
          fileImage.type === "image/jpg" ||
          fileImage.type === "image/jpeg"
        ) {
          let reader = new FileReader();

          reader.onload = function (e) {
            _this.locationModalAddUpdateForm.srcImage = e.target.result;
            _this.locationModalAddUpdateForm.files = fileImage;
          };
          reader.readAsDataURL(fileImage);
        } else {
          Vue.swal({
            icon: "error",
            title: "Lokasyon Ekle - Resim",
            html: "Yalnızca png, jpg ve jpeg formatlı resimleri yükleyebilirsiniz!",
            confirmButtonText: "Tamam",
          });
        }
      }
    },

    onGridReady(params) {
      // Grid ready olduğunda
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },

    cleanLocationModalAddUpdateForm() {
      // TODO cleanLocationModalAddUpdateForm
      this.locationModalAddUpdateForm._id = "";
      this.locationModalAddUpdateForm.status = true;
      this.locationModalAddUpdateForm.city = "";
      this.locationModalAddUpdateForm.locationName = "";
      this.locationModalAddUpdateForm.locationNameEn = "";
      this.locationModalAddUpdateForm.locationNameDe = "";
      this.locationModalAddUpdateForm.theme = false;
      this.locationModalAddUpdateForm.blogPhotos = [];
      this.$refs.blogFiles.value = "";
      this.locationModalAddUpdateForm.iataCode = "";
      this.locationModalAddUpdateForm.title = "";
      this.locationModalAddUpdateForm.description = "";
      this.locationModalAddUpdateForm.srcImage = "";
      this.locationModalAddUpdateForm.siteTitleTr = "";
      this.locationModalAddUpdateForm.siteTitleEn = "";
      this.locationModalAddUpdateForm.siteTitleDe = "";
      this.locationModalAddUpdateForm.slug = "";
      this.locationModalAddUpdateForm.slugEn = "";
      this.locationModalAddUpdateForm.slugDe = "";
      this.locationModalAddUpdateForm.oldSlug = "";
      this.locationModalAddUpdateForm.richText = "";

      this.locationModalAddUpdateForm.content = {
        tr: {
          title: "",
          description: "",
          richText: "",
        },

        en: {
          title: "",
          description: "",
          richText: "",
        },
        de: {
          title: "",
          description: "",
          richText: "",
        },
      };
      this.locationModalAddUpdateForm.microData = {
        tr: [
          {
            question: "",
            answer: "",
          },
        ],
        en: [
          {
            question: "",
            answer: "",
          },
        ],
        de: [
          {
            question: "",
            answer: "",
          },
        ],
      };
      this.locationModalAddUpdateForm.campaignImagesValue = [];
    },

    getCities() {
      this.$Progress.start();
      axios
        .get(process.env.VUE_APP_API_URL + "citylocation/sitemap")
        .then((response) => {
          this.cities = response.data;
          this.cities.forEach((city) => {
            this.cityNames.push(city.city);
          });
        });
    },

    searchLocation() {
      this.$Progress.start();
      axios
        .get(process.env.VUE_APP_API_URL + "admin/location", {
          params: this.searchLocationForm,
        })
        .then((response) => {
          this.$Progress.finish();
          this.rowData = response.data.locationList;
          this.searchLocationFormCollapsed = false;
          this.locationsGridShow = true;
        });
    },
    getLocationFindSlug(slug){
      axios.get(process.env.VUE_APP_API_URL + 
      "location/slug?slug=" + slug
      ).then((response) => {
        console.log("response",response)
        console.log("2",response.data.locationList[0]);
        var _this = this;
        const findedLocation=response.data.locationList[0]

      _this.locationModalAddUpdateOptions.process = "update";
      _this.locationModalAddUpdateOptions.title = "Lokasyonu Güncelle";
      _this.locationModalAddUpdateForm._id = findedLocation._id;

      _this.locationModalAddUpdateForm.title = findedLocation.title;
      _this.locationModalAddUpdateForm.schendler = findedLocation.schendler;
      _this.locationModalAddUpdateForm.description =
      findedLocation.description;
      _this.locationModalAddUpdateForm.srcImage =
        _this.ENV_URL_LOCATION + findedLocation.image;
      _this.locationModalAddUpdateForm.blogPhotos =
      findedLocation?.blogPhotos?.map(
          (item) => _this.ENV_URL_LOCATION + item
        ) || [];

      _this.locationModalAddUpdateForm.files = null;
      _this.locationModalAddUpdateForm.campaignImagesValue =
      findedLocation.campaignImagesValue;

      _this.locationModalAddUpdateForm.status = findedLocation.status;
      _this.locationModalAddUpdateForm.city = findedLocation.city;
      _this.locationModalAddUpdateForm.locationName =
      findedLocation.locationName;
      _this.locationModalAddUpdateForm.locationNameEn =
      findedLocation.locationNameEn;
      _this.locationModalAddUpdateForm.locationNameDe =
      findedLocation.locationNameDe;
      _this.locationModalAddUpdateForm.theme = findedLocation.theme;
      _this.locationModalAddUpdateForm.iataCode = findedLocation.iataCode;
      _this.locationModalAddUpdateForm.siteTitleTr =
      findedLocation.siteTitleTr;
      _this.locationModalAddUpdateForm.siteTitleEn =
      findedLocation.siteTitleEn;
      _this.locationModalAddUpdateForm.siteTitleDe =
      findedLocation?.siteTitleDe;
      _this.locationModalAddUpdateForm.slug = findedLocation.slug;
      _this.locationModalAddUpdateForm.slugEn = findedLocation.slugEn;
      _this.locationModalAddUpdateForm.slugDe = findedLocation.slugDe;
      _this.locationModalAddUpdateForm.richText = findedLocation.richText;

      _this.locationModalAddUpdateForm.oldSlug = findedLocation.slug;
      _this.locationModalAddUpdate = true;

      if (findedLocation.microData) {
        _this.locationModalAddUpdateForm.microData = findedLocation.microData;
      }
      if (findedLocation.content) {
        _this.locationModalAddUpdateForm.content.tr =
        findedLocation.content.tr;
        _this.locationModalAddUpdateForm.content.en =
        findedLocation.content.en;
        _this.locationModalAddUpdateForm.content.de =
        findedLocation.content.de;

        /** 
        _this.locationModalAddUpdateForm.content.de.title = params?.node?.data?.content?.de?.title || "test";
        _this.locationModalAddUpdateForm.content.de.description = params?.node?.data?.content?.de?.description || "test";
        */
      }
  
        });
    
    },

    async locationAddUpdate(params) {
      var formData = new FormData(); // TODO Form data

      if (
        this.locationModalAddUpdateForm.files !== null &&
        this.locationModalAddUpdateForm.files.size > 0
      )
        formData.append("image", this.locationModalAddUpdateForm.files);
      else if (
        this.locationModalAddUpdateForm.srcImage ===
        this.ENV_URL_LOCATION + this.defaultLocationImage
      )
        formData.append("image", this.defaultLocationImage);

      if (this.locationModalAddUpdateOptions.process === "update") {
        formData.append("_id", params._id);
      }

      if (this.selectedSite.selected == true) {
        formData.append("subSite", JSON.stringify(params.subSite));
      }

      formData.append("content", JSON.stringify(params.content));
      formData.append("microData", JSON.stringify(params.microData));

      formData.append("status", params.status);
      formData.append("city", params.city);
      formData.append("locationName", params.locationName);
      formData.append("locationNameEn", params.locationNameEn);
      formData.append("locationNameDe", params.locationNameDe);
      formData.append("theme", params.theme);
      formData.append("iataCode", params.iataCode);

      // slug oluştururken yaşanan sorun sebebiyle lokasyon girilemiyor. bu şekilde bir çözüm ürettim
      if (this.locationModalAddUpdateOptions.process != "update") {
        var _slug = Func.slugify(this.locationModalAddUpdateForm.locationName);
        var _slugEn = Func.slugify(
          this.locationModalAddUpdateForm.locationNameEn
        );
        var _slugEn = Func.slugify(
          this.locationModalAddUpdateForm.locationNameEn
        );
        var _slugDe = Func.slugify(
          this.locationModalAddUpdateForm.locationNameDe
        );
        formData.append("slug", _slug);
        formData.append("slugEn", _slugEn);
        formData.append("slugDe", _slugDe);
        formData.append("oldSlug", params.oldSlug);
      }

      //Lokasyon güncelleme yaparken DE slug ekleme
      if (
        this.locationModalAddUpdateOptions.process == "update" &&
        this.locationModalAddUpdateForm.slugDe
      ) {
        var _slug = Func.slugify(this.locationModalAddUpdateForm.locationName);
        var _slugEn = Func.slugify(
          this.locationModalAddUpdateForm.locationNameEn
        );
        var _slugEn = Func.slugify(
          this.locationModalAddUpdateForm.locationNameEn
        );
        var _slugDe = Func.slugify(
          this.locationModalAddUpdateForm.locationNameDe
        );
        formData.append("slug", _slug);
        formData.append("slugEn", _slugEn);
        formData.append("slugDe", _slugDe);
        formData.append("oldSlug", params.oldSlug);
      }

      formData.append(
        "campaignImagesValue",
        JSON.stringify(params.campaignImagesValue)
      );
      formData.append("siteTitleTr", params.siteTitleTr);
      formData.append("siteTitleEn", params.siteTitleEn);
      formData.append("siteTitleDe", params.siteTitleDe);

      params.blogPhotos.forEach((item) => {
        if (item.includes(this.ENV_URL_LOCATION))
          item = item.replace(this.ENV_URL_LOCATION, "");
        formData.append("blogPhotos", item);
      });
      this.$Progress.start();
      this.locationModalAddUpdateBtnDisable = true; // Modal'daki post button'u disable ettik.

      if (this.locationModalAddUpdateOptions.process === "add") {
        axios
          .post(process.env.VUE_APP_API_URL + "admin/location", formData)
          .then((response) => {
            this.$Progress.finish();
            this.locationModalAddUpdateBtnDisable = false; // Modal'daki post button'u enable ettik.

            if (response.data.result === "success") {
              if (this.locationsGridShow) this.searchLocation(); // AG Grid açıksa (Yani daha önce arama yapıldıysa) son aramayı yeniden yap
              this.locationModalAddUpdate = false; // Modal'ı kapattık
            }

            Vue.swal({
              icon: response.data.result,
              title: "Lokasyon Ekle",
              html: response.data.message,
              confirmButtonText: "Tamam",
            });
          });
      } else if (this.locationModalAddUpdateOptions.process === "update") {
        axios
          .put(process.env.VUE_APP_API_URL + "admin/location", formData)
          .then((response) => {
            this.$Progress.finish();
            this.locationModalAddUpdateBtnDisable = false; // Modal'daki post button'u enable ettik.

            if (response.data.result === "success") {
              if (this.locationsGridShow) this.searchLocation(); // AG Grid açıksa (Yani daha önce arama yapıldıysa) son aramayı yeniden yap
              this.locationModalAddUpdate = false; // Modal'ı kapattık
            }

            Vue.swal({
              icon: response.data.result,
              title: "Lokasyon Düzenle",
              html: response.data.message,
              confirmButtonText: "Tamam",
            });
          });
      }
    },

    locationDelete(params) {
      this.$Progress.start();
      axios
        .delete(process.env.VUE_APP_API_URL + "admin/location/" + params._id)
        .then((response) => {
          this.$Progress.finish();
          this.searchLocation();
          if (response.data.result === "success") {
            if (this.locationsGridShow) this.searchLocation(); // AG Grid açıksa (Yani daha önce arama yapıldıysa) son aramayı yeniden yap
            this.locationModalAddUpdate = false; // Modal'ı kapattık
          }

          Vue.swal({
            icon: response.data.result,
            title: "Lokasyon Sil",
            html: response.data.message,
            confirmButtonText: "Tamam",
          });
        })
        .finally(() => {
          this.locationModalAddUpdate = false;
        });
    },

    onRowDoubleClicked(params) {
      this.cleanLocationModalAddUpdateForm();
      this.cleanSubSiteLocationModalAddUpdateForm();
      this.getLocationFindSlug(params.data.slug)

      _this.locationModalAddUpdateForm.title = params.node.data.title;
      _this.locationModalAddUpdateForm.schendler = params.node.data.schendler;
      _this.locationModalAddUpdateForm.description =
        params.node.data.description;
      _this.locationModalAddUpdateForm.srcImage =
        _this.ENV_URL_LOCATION + params.node.data.image;
      _this.locationModalAddUpdateForm.blogPhotos =
        params.node.data?.blogPhotos?.map(
          (item) => _this.ENV_URL_LOCATION + item
        ) || [];

      _this.locationModalAddUpdateForm.files = null;
      _this.locationModalAddUpdateForm.campaignImagesValue =
        params.node.data.campaignImagesValue;

      _this.locationModalAddUpdateForm.status = params.node.data.status;
      _this.locationModalAddUpdateForm.city = params.node.data.city;
      _this.locationModalAddUpdateForm.locationName =
        params.node.data.locationName;
      _this.locationModalAddUpdateForm.locationNameEn =
        params.node.data.locationNameEn;
      _this.locationModalAddUpdateForm.locationNameDe =
        params.node.data.locationNameDe;
      _this.locationModalAddUpdateForm.theme = params.node.data.theme;
      _this.locationModalAddUpdateForm.iataCode = params.node.data.iataCode;
      _this.locationModalAddUpdateForm.siteTitleTr =
        params.node.data.siteTitleTr;
      _this.locationModalAddUpdateForm.siteTitleEn =
        params.node.data.siteTitleEn;
      _this.locationModalAddUpdateForm.siteTitleDe =
        params?.node?.data?.siteTitleDe;
      _this.locationModalAddUpdateForm.slug = params.node.data.slug;
      _this.locationModalAddUpdateForm.slugEn = params.node.data.slugEn;
      _this.locationModalAddUpdateForm.slugDe = params.node.data.slugDe;
      _this.locationModalAddUpdateForm.richText = params.node.data.richText;

      _this.locationModalAddUpdateForm.oldSlug = params.node.data.slug;
      _this.locationModalAddUpdate = true;

      if (params.node.data.microData) {
        _this.locationModalAddUpdateForm.microData = params.node.data.microData;
      }
      if (params.node.data.content) {
        _this.locationModalAddUpdateForm.content.tr =
          params.node.data.content.tr;
        _this.locationModalAddUpdateForm.content.en =
          params.node.data.content.en;
        _this.locationModalAddUpdateForm.content.de =
          params.node.data.content.de;

        /** 
        _this.locationModalAddUpdateForm.content.de.title = params?.node?.data?.content?.de?.title || "test";
        _this.locationModalAddUpdateForm.content.de.description = params?.node?.data?.content?.de?.description || "test";
        */
      }
    },
  },
  created: function () {
    this.getCities();
    this.searchLocation();
    this.getCampaigns();
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
.search-schedule {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.blog-files-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.blog-files-header label:nth-child(2) {
  border: 1px solid rgba(187, 186, 186, 0.406);
  border-radius: 5px;
  padding: 5px 20px;
  cursor: pointer;
  margin-left: 1rem;
}

.blog-photo {
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
}

.blog-photo-wr {
  background-color: #eaeaea !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  margin-bottom: 1rem;
  height: 200px;
  width: 300px;
  position: relative;
}

.blog-photo-wr svg {
  position: absolute;
  top: 0;
  right: 0;
  margin: 5px 5px !important;
  cursor: pointer !important;
}

.blog-photo-wr svg path {
  color: white;
}

.not-found-photo {
  width: 100%;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 200px;
}
.red {
  background-color: red !important;
  color: white !important;
}
.select {
  margin-top: -5px;
  margin-bottom: -5px;
}
.custom-divider2 {
  margin-top: -2px;
  margin-bottom: -2px;
}
.topRow {
  margin-top: -15px !important;
  margin-bottom: 5px !important;
}
</style>
